import React from 'react';
import { CalendlyOptions } from 'src/types/calendly';

interface CalendlyState {
  isPopupVisible: boolean;
  closePopup: () => void;
  showPopup: (options: CalendlyOptions) => void;
  options: CalendlyOptions | null;
}

const initialState: CalendlyState = {
  isPopupVisible: false,
  closePopup: () => null,
  showPopup: () => null,
  options: {
    riaDeal: undefined,
    appointmentType: null,
    advisor: null,
    onEventScheduled: undefined,
  },
};

export const CalendlyContext = React.createContext<CalendlyState>(initialState);
